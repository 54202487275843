/*
 * Paginator - komponenta (užití na HP recepty.cz)
 * užití pro ajax i non-ajax akce
 */
(function( $ ) {
	$.fn.componentPaginator = function( options ) {
		$(document).ready(function() {
			var actualUrl = '/kategorie/2008/?page='; // id 2008 je číslo základní rubriky (shodné na devu i live)
			var actualPageNumber = options.actualPageNumber;

			//Aktuální stránka - nastavení proměnné pro další počítání + nastavení odkazu
			$('#paginator--item-actual').attr('href', actualUrl + actualPageNumber).html(actualPageNumber);

			//BTN DALŠÍ: ">"
			$('#paginator--item-next').attr('href', actualUrl + (actualPageNumber + 1));

			//Pravidla když je [page] > 1
			if (actualPageNumber > 1) {
				var lastPage = $('#paginator--item-last').html().split(' ').pop();

				//Reset odkazů vzniklých při stránkování
				$('#paginator a').hide();

				//Označení listů (div obalující <article> tagy) - pro využití kotev
				$('.paginator--page').each(function(index){
					$(this).attr('id', 'paginator--page-' + (index + 1));
				});

				//Nastavení plnění a zobrazení základních stránek v elementu <a></a> (předchozí, další, první, poslední, aktuální)
				$('#paginator--item-prev').attr('href', actualUrl + (actualPageNumber - 1)).show();
				$('#paginator--item-prev').after( '<a id="paginator--item-' + (actualPageNumber - 1) + '" href="' + actualUrl + (actualPageNumber - 1) + '" class="ajax" data-ga="previous" data-page="' +  (actualPageNumber - 1) + '">' + (actualPageNumber - 1) + '</a>' );
				$('#paginator--item-next').show();
				$('#paginator--item-actual').show();
				$('#paginator--item-last').show();

				if ( ( actualPageNumber + 1 ) < lastPage) {
					$('#paginator--item-actual').after( '<a id="paginator--item-' + (actualPageNumber + 1) + '" href="' + actualUrl + (actualPageNumber + 1) + '" class="ajax" data-ga="next" data-page="' +  (actualPageNumber + 1) + '">' + (actualPageNumber + 1) + '</a>' );
				}
				if ( actualPageNumber > 2 ) {
					$('#paginator--item-prev').after( '<a id="paginator--item-' + (actualPageNumber - 2) + '" href="' + actualUrl + (actualPageNumber - 2) + '" class="ajax" data-ga="previous" data-page="' +  (actualPageNumber - 1) + '">' + (actualPageNumber - 2) + '</a>' );
				}
				if ( actualPageNumber > 3 ) {
					$('#paginator--item-prev').after($('#paginator--item-first'));
					$('#paginator--item-first').show();
				}
				if ( actualPageNumber >= lastPage ) {
					$('#paginator--item-last').hide();
					$('a.load').hide();
				}
				if ( (actualPageNumber + 1 ) > lastPage ) {
					$('#paginator--item-next').hide();
				}
			}

			$('a[data-ga]').on('click', function(e){ 
				
				var pagePress;

				if ($(this).attr('id') == 'paginator--item-prev') {
					pagePress = actualPageNumber - 1;
				}

				else if ($(this).attr('id') == 'paginator--item-next') {
					pagePress = actualPageNumber + 1;
				}
				
				else {
					pagePress = $(this).text().replace(/[^0-9]/g, '');
				}
				
				dataLayer.push({
				    'event': 'page_' + $(this).data('ga'),
				    'pagination': {
        				'page': pagePress
    				}
				});
			});
		});
	};
}( jQuery ));
